import { catchError, tap } from 'rxjs/operators';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ErrorFormService, InclusionCardioService } from '@services/form';
import { PatientService } from '@services/patient';
import { AuthService } from '@services/security';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormPatientCardioService } from '@services/form/form-patient-cardio.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ConfigService } from '@services/config';
import { format, startOfDay } from 'date-fns';
import { ConfirmationModalComponent } from '@ui/confirmation-modal/confirmation-modal.component';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { InclusionFastConfirmationComponent } from '@app/patients/modals/inclusion-fast-confirmation/inclusion-fast-confirmation.component';
import { throwError } from 'rxjs';
import { FileSignatureComponent } from '@ui/icon/components/file-signature/file-signature.component';
import { capitalize, deburr } from 'lodash';

@UntilDestroy()
@Component({
    templateUrl: './inclusion-fast.component.html',
    styleUrls: ['./inclusion-fast.component.scss'],
})
export class InclusionFastComponent implements OnInit {
    patient;

    @ViewChild('placesRef') placesRef: GooglePlaceDirective;

    public potentialPatient:
        | { firstName: string; lastName: string; _id: string; createdAt: any }
        | undefined = undefined;
    private modalRef?: BsModalRef<unknown>;
    get comment() {
        return this.inclusionService.comment;
    }
    set comment(comment) {
        this.inclusionService.comment = comment;
    }
    errors: { field: string; error: string }[];
    currentUser;
    careStaffs;
    includePatientLoading$ = new BehaviorSubject(false);
    identityDocOptions;

    public get presciptionDateString(): string {
        return format(new Date(), 'dd/MM/yyyy');
    }

    @Output()
    onClose = new EventEmitter<any>();
    seeinfo = false;
    fileSignature = FileSignatureComponent;
    constructor(
        public bsModalRef: BsModalRef,
        private router: Router,
        private inclusionService: InclusionCardioService,
        private formPatientCardioService: FormPatientCardioService,
        private errorService: ErrorFormService,
        private patientService: PatientService,
        private toastr: ToastrService,
        private translate: TranslateService,
        public authService: AuthService,
        private configService: ConfigService,
        private change: ChangeDetectorRef,
        private readonly modalService: BsModalService,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.inclusionService.initInclusionFast().subscribe(() => {
            this.initErrors();
            this.initCurrentUser();
            this.initPatient();
            this.configService.getIdentityDocOptions().subscribe((value) => {
                if (value?.length) {
                    this.identityDocOptions = value;
                    this.change.detectChanges();
                }
            });
        });
    }

    private initPatient(): void {
        this.patient = this.inclusionService.patient;
        this.careStaffs = this.formPatientCardioService.careStaffs;
        if (this.authService.isAdmin) {
            this.patient.inclusion.enableScale = true;
            this.patient.inclusion.enableTensio = true;
        }
    }

    hasError(error: string): boolean {
        return this.errors?.some(({ field }) => field === error);
    }

    handleAddressChange(address): void {
        this.patient.address.completeAddress = address.formatted_address;

        address.address_components.forEach(({ long_name, types }) => {
            if (types.includes('country')) {
                this.patient.address.country = long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                this.patient.address.region = long_name;
            }
            if (types.includes('administrative_area_level_2')) {
                this.patient.address.department = long_name;
            }
            if (types.includes('locality')) {
                this.patient.address.city = long_name;
            }
            if (types.includes('postal_code')) {
                this.patient.address.zipCode = long_name;
            }
            if (types.includes('route')) {
                this.patient.address.street = long_name;
            }
            if (types.includes('street_number')) {
                this.patient.address.number = long_name;
            }
        });
    }

    oncheckPatientExists($event, field) {
        if (!this.patient.firstName || !this.patient.socialSecurityNumber) {
            this.removeError(field);
            return;
        }
        this.getPotentialPatient(
            this.patient.firstName,
            this.patient.socialSecurityNumber,
        )
            .pipe(
                tap((potentialPatient) => {
                    this.potentialPatient = potentialPatient;
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    removeError(error: string): void {
        this.errorService.removeError(error);
    }

    selectCardio($event) {
        this.patient.specialistId = this.careStaffs?.cardio.find(
            (item) => this.displayName(item) === $event.target.value,
        );

        this.patient.inclusion.modalTemplate =
            !!this.patientService.checkPrescriptionTemplate(
                this.patient.specialistId,
            );

        this.removeError('specialistId');
    }

    onDateChange(date: Date): void {
        if (!date) {
            return;
        }

        const timestamp = startOfDay(date).valueOf();

        if (this.patient.birthDate !== timestamp) {
            this.patient.birthDate = timestamp;
            this.removeError('birthDate');
        }
    }

    findCardio(user) {
        return user ? this.displayName(user) : '';
    }

    displayName(user) {
        if (user.sharedProfile) {
            return user.companyName;
        }

        if (user.firstName || user.lastName) {
            return `${user.lastName} ${user.firstName}`;
        }

        return user.fullName;
    }

    onRemovePrescription(e) {
        e.stopPropagation();

        this.patient.prescriptionFileName = '';
        this.patient.prescriptionFileType = '';
    }

    onSelectPrescription(prescription) {
        this.patient.prescriptionFileName = prescription.name;
        this.patient.prescriptionFileType = prescription.type;
        this.patient.prescription = prescription;
    }

    checkModalTemplate() {
        if (this.authService.isSpecialist) {
            return this.patientService.checkPrescriptionTemplate(
                this.currentUser,
            );
        }

        return this.patientService.checkPrescriptionTemplate(
            this.patient.specialistId,
        );
    }

    hasFeedbackError(feedback: string): boolean {
        return this.errors.some(({ error }) => error?.endsWith(feedback));
    }

    openConfirmationModal(patient) {
        this.includePatientLoading$.next(true);
        const confirmationModal = this.modalService.show(
            InclusionFastConfirmationComponent,
            {},
        );
        confirmationModal.onHidden.subscribe(() => {
            this.includePatientLoading$.next(false);
            this.redirectInclusion(patient, false);
        });
        confirmationModal.content.onInclude.subscribe(() => {
            this.onClose.emit(patient);
            this.redirectInclusion(patient, false);
        });
        confirmationModal.content.onIncludeAndComplete.subscribe(() => {
            this.onClose.emit(patient);
            this.redirectInclusion(patient, true);
        });
    }

    includePatient() {
        if (!this.patient.birthLastName) {
            const deburredLastName = capitalize(deburr(this.patient.lastName));
            this.patient.birthLastName = deburredLastName.replace(
                /[^a-zA-Z0-9\-' ]/g,
                '',
            );
        } else {
            const deburredBirthLastName = capitalize(deburr(this.patient.birthLastName));
            this.patient.birthLastName = deburredBirthLastName.replace(
                /[^a-zA-Z0-9\-' ]/g,
                '',
            );
        }
        if (!this.patient.birthFirstNames) {
            this.patient.birthFirstNames = this.patient.firstName;
        }

        this.includePatientLoading$.next(true);
        let patient;
        this.inclusionService
            .includedPatient()
            .pipe(
                tap((result) => {
                    patient = result.patient;
                    this.toastIncludeSucess(patient);
                    this.openConfirmationModal(patient);
                }),
                catchError((err, caught) => {
                    this.includePatientLoading$.next(false);
                    return throwError(err);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private getPotentialPatient(
        firstName: string,
        socialSecurityNumber: string,
    ) {
        return this.patientService.searchPatient(
            firstName,
            socialSecurityNumber,
        );
    }

    toastIncludeSucess(patient) {
        const msg = this.translate.instant('inclusion.fastsuccess', {
            userName: `${patient.firstName} ${patient.lastName}`,
            patientId: patient._id,
        });
        this.toastr.success(msg, null, {
            enableHtml: true,
            messageClass: 'toastLink',
            closeButton: true,
        });
    }

    redirectInclusion(patient, goComplete) {
        this.bsModalRef.hide();
        if (goComplete) {
            this.router.navigate(['edition', patient._id]);
        }
    }

    public tensioUpdated(event): void {
        const checkbox = event.target as HTMLInputElement;
        if (checkbox.checked) {
            this.patient.inclusion.enableTensio = true;
        } else {
            event.preventDefault();
            const modal = this.modalService.show(ConfirmationModalComponent, {
                initialState: {
                    title: '!! ATTENTION !!',
                    message: 'profile.configuration.devices.confirm_patient',
                    layout: 'center',
                    textZone: true,
                },
            });

            modal.content.onConfirm.subscribe({
                next: () => {
                    this.patient.inclusion.enableTensio = false;
                    if (modal.content.comment) this.patient.inclusion.comment = modal.content.comment;
                },
            });
        }
    }

    private initErrors(): void {
        this.errorService.errors
            .pipe(
                tap((errors) => {
                    this.errors = errors;
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private initCurrentUser(): void {
        this.authService.currentUser
            .pipe(
                tap((currentUser) => {
                    this.currentUser = currentUser;
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    onNoVerifiedIdChange($event: Event) {
        if (this.patient.identityDocConfirmation) {
            this.patient.identityDoc = undefined;
            this.cdr.detectChanges();
        }
    }

    async goToPotentialPatient() {
        await this.router.navigate(['/patient', this.potentialPatient._id]);
        return this.bsModalRef.hide();
    }

    openModal(template: TemplateRef<void>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    }
}
